import 'zone.js/plugins/zone-error';
const baseUrl = 'dev.pia.publicare-gmbh.de';
export const environment = {
  production: false,
  disableDeploy: true,
  baseUrl,
  branch: 'dev',
  o365Scopes: ['user.read'],
  firebase: {
    apiKey: 'AIzaSyAD6FKSPI29FC8QYsJuqq9qWKsFwlIYy9o',
    authDomain: 'pia-dev-cf02a.firebaseapp.com',
    projectId: 'pia-dev-cf02a',
    storageBucket: 'pia-dev-cf02a.appspot.com',
    messagingSenderId: '684228989784',
    appId: '1:684228989784:web:5654fba7f4cd2b363b0b2d',
    databaseURL: '',
  },
  amplitude: {
    apiKey: 'f1d322318d27071d9e4d220d18d4e62e',
  },
  sentry: {
    dsn: 'https://98deaa93d83b4c0b84c0cc95aca4140a@o493535.ingest.sentry.io/5563089',
  },
  authCodeFlowConfigWeb: {
    issuer: `https://auth.${baseUrl}`,
    redirectUri: window.location.origin,
    clientId: '14165ce6-5d71-4c2b-8753-582f3df45241',
    responseType: 'code',
    scope: 'openid offline_access',
    clearHashAfterLogin: true,
    showDebugInformation: true,
    skipIssuerCheck: true,
  },
  authCodeFlowConfigApp: {
    client_id: '14165ce6-5d71-4c2b-8753-582f3df45241',
    server_host: `https://auth.${baseUrl}`,
    redirect_url: 'appauth://callback',
    end_session_redirect_url: 'appauth://endsession',
    scopes: 'openid offline_access',
    pkce: true,
  },
  codePushDeploymentKeys: [
    { deploymentKey: '', platform: 'android', deployment: 'dev' },
    { deploymentKey: '', platform: 'android', deployment: 'staging' },
    { deploymentKey: '', platform: 'android', deployment: 'production' },
    { deploymentKey: '', platform: 'ios', deployment: 'dev' },
    { deploymentKey: '', platform: 'ios', deployment: 'staging' },
    { deploymentKey: '', platform: 'ios', deployment: 'production' },
  ],
  stimulsoftLicense:
    '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkyf6GCtMIvMrFSuO65hDII/QxiVSjc6dLKFeN+mcA+0g9Y8N' +
    'YAkJWK1pE0VMMEuPCcbPX4qqV/OscTTDZ9nZkNY1ZsJ+IXvS1tjgciZIPEhZuTKiWUkl9emnEch7g/tOA/M0Lrrzh5' +
    'uAeiRBh1C6PaZMi9GlwqZXPZS8TWURbZm1CKvuRC49Lk8b6U663R/LgjzaH0dzOGZOndv0NcDFSgy7IHLPuJMTgIGJ' +
    'd7Hoqsh1nHsZ6bJiS108btCoAxsi/7M2foOje3BiJj3I5Q+Dj+/L2eeczjrKE92CUxAlPBqMnXha3/MLylWJF1lchE' +
    'DTclhy+03+blMfwCp+1Liy2VwZAx1g1pliwaO1zkJtVCK0Zx200kEGqlD4w4+EMPHVmAaDZmpx/mOGBV+LzpL5NxLz' +
    'V/hbRgbSWdiB0eC6mRGpMmhPAfkZYw/lG22gW5MpcYey4nlpGn4Cnk4GXvzwoLzWkEaLCXx2xKTOZOP2tBvAU4nkcx' +
    'aCqQo9h9BE5D+tyGy1bQE6oTkEnjPDIvhb6K',
  calendar: {
    settingsPath: `${baseUrl}/setting/calendar`,
    apiPath: `api.${baseUrl}/calendar`,
    helpLink: {
      url: `https://puma.publicare-gmbh.de/display/HILFEPIA/HILFE.PIA`,
      label: `hilfe.pia`,
    },
    appLinkLabel: `${baseUrl}`,
    clientId: 'db29b12f-f48d-4b19-b979-9c2eb23545ac',
    redirectUri: `https://${baseUrl}`,
  },
  encryption: {
    regex: null,
    settings: [],
  },
  sendAllCommandsToEmail: `FullStack@publicare-gmbh.de`,
  environmentSettings: {
    apiUrl: `https://api.${baseUrl}`,
    swodocUrl: `https://formulare.${baseUrl}`,
  },
  createTenant: {
    defaultUserSuffix: `${baseUrl}`,
  },
  validITLabsUser: ['timm.hamers@publicare-gmbh.de', 'marc.bach@publicare-gmbh.de'],
  markerjs2Key: 'MJS2-P947-M765-5857',
};
